import { createEnv } from '@t3-oss/env-nextjs'
import * as z from 'zod'
import { looseHrefSchema, envIs, requiredIf } from '@purposity/utils'
import { PURPOSITY_ENV } from './constants.mjs'

export const env = createEnv({
  server: {
    NODE_ENV: z.enum(['development', 'test', 'production']).default('development'),
    ADMIN_API_SECRET: z.string(),
    ADMIN_SITE_ORIGIN: z.string(),
    API_ENDPOINT: z.string(),
    API_ORIGIN: z.string(),
    CLERK_JWT_KEY: z.string(),
    CLERK_SECRET_KEY: z.string().refine((v) => v.startsWith('sk_')),
    CLERK_WEBHOOK_SECRET: z.string(),
    DOPPLER_CONFIG: z.union([z.literal('prod'), z.literal('preview'), z.literal('dev'), z.string()]),
    DOPPLER_ENVIRONMENT: z.union([z.literal('prod'), z.literal('preview'), z.literal('dev')]),
    DOPPLER_PROJECT: z.string(),

    FIREBASE_CLIENT_EMAIL: z.string().email(),
    FIREBASE_PRIVATE_KEY: z.string(),

    GEOCODIO_API_KEY: z.string(),
    HASURA_WEBHOOK_SECRET: z.string(),
    HOOKDECK_SECRET: z.string(),
    LEGACY_API_ORIGIN: z.string(),
    LEGACY_JWT_KEY: z.string(),
    LOGTAIL_SOURCE_TOKEN: z.string(),

    QUIRREL_API_URL: z.string(),
    QUIRREL_TOKEN: z.string(),
    SECRET_KEY: z.string(),
    SENTRY_AUTH_TOKEN: z.string().catch(requiredIf(envIs(['production'])(PURPOSITY_ENV), '')),
    SLACK_WH_PATH: z.string(),
    STRIPE_SECRET_KEY: z.string(),
    STRIPE_WEBHOOK_SECRET: z.string(),
    TIP_PRODUCT: z.string(),
    UPSTASH_REDIS_REST_TOKEN: z.string(),
    UPSTASH_REDIS_REST_URL: z.string(),

    PORT: z.coerce.number().default(3030),
    VERCEL_URL: looseHrefSchema.catch(requiredIf(envIs(['production', 'preview'])(PURPOSITY_ENV), 'http://localhost:3030')),
  },
  client: {
    NEXT_PUBLIC_API_ORIGIN: z.string(),
    NEXT_PUBLIC_APP_ORIGIN: z.string().default('http://localhost:3000'),
    NEXT_PUBLIC_CDN_ORIGIN: z.string(),
    NEXT_PUBLIC_CLERK_AUTH_DOMAIN: z.string(),
    NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN: z.string().optional(),
  },
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    ADMIN_API_SECRET: process.env.ADMIN_API_SECRET,
    ADMIN_SITE_ORIGIN: process.env.ADMIN_SITE_ORIGIN,
    API_ENDPOINT: process.env.API_ENDPOINT,
    API_ORIGIN: process.env.API_ORIGIN,
    CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
    CLERK_JWT_KEY: process.env.CLERK_JWT_KEY,
    CLERK_WEBHOOK_SECRET: process.env.CLERK_WEBHOOK_SECRET,
    DOPPLER_CONFIG: process.env.DOPPLER_CONFIG,
    DOPPLER_ENVIRONMENT: process.env.DOPPLER_ENVIRONMENT,
    DOPPLER_PROJECT: process.env.DOPPLER_PROJECT,
    FIREBASE_CLIENT_EMAIL: process.env.FIREBASE_CLIENT_EMAIL,
    FIREBASE_PRIVATE_KEY: process.env.FIREBASE_PRIVATE_KEY,
    GEOCODIO_API_KEY: process.env.GEOCODIO_API_KEY,
    HASURA_WEBHOOK_SECRET: process.env.HASURA_WEBHOOK_SECRET,
    HOOKDECK_SECRET: process.env.HOOKDECK_SECRET,
    LEGACY_API_ORIGIN: process.env.LEGACY_API_ORIGIN,
    LEGACY_JWT_KEY: process.env.LEGACY_JWT_KEY,
    LOGTAIL_SOURCE_TOKEN: process.env.LOGTAIL_SOURCE_TOKEN,
    PORT: process.env.PORT,
    QUIRREL_API_URL: process.env.QUIRREL_API_URL,
    QUIRREL_TOKEN: process.env.QUIRREL_TOKEN,
    SECRET_KEY: process.env.SECRET_KEY,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    SLACK_WH_PATH: process.env.SLACK_WH_PATH,
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
    TIP_PRODUCT: process.env.TIP_PRODUCT,
    UPSTASH_REDIS_REST_TOKEN: process.env.UPSTASH_REDIS_REST_TOKEN,
    UPSTASH_REDIS_REST_URL: process.env.UPSTASH_REDIS_REST_URL,
    VERCEL_URL: process.env.VERCEL_URL,
    NEXT_PUBLIC_API_ORIGIN: process.env.NEXT_PUBLIC_API_ORIGIN,
    NEXT_PUBLIC_APP_ORIGIN: process.env.NEXT_PUBLIC_APP_ORIGIN,
    NEXT_PUBLIC_CDN_ORIGIN: process.env.NEXT_PUBLIC_CDN_ORIGIN,
    NEXT_PUBLIC_CLERK_AUTH_DOMAIN: process.env.NEXT_PUBLIC_CLERK_AUTH_DOMAIN,
    NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN: process.env.NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN,
  },
  skipValidation: process.env.SKIP_ENV_VALIDATION === 'true',
})
