var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3ufM1ahaMeL3KL4ourMG1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import {
  init,
  BrowserTracing,
  BrowserOptions,
  addGlobalEventProcessor,
} from '@sentry/nextjs'
import { PURPOSITY_ENV, SENTRY_DSN } from './constants.mjs'
import { env } from './env.mjs'
import { isCheckly, ZodProcessor } from './sentry.common'

init({
  dsn: SENTRY_DSN,
  environment: PURPOSITY_ENV,

  tracesSampleRate: 1.0,

  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        /** MATCH localhost */
        'localhost',
        /** MATCH relative paths */
        /^\//,
        /** MATCH `purposity.com` and all subdomains, except for `clerk` */
        /^(?!clerk\.)([a-zA-Z0-9-_]+\.)*purposity\.com$/,
        /** MATCH `purposity.app` and all subdomains */
        /^([a-zA-Z0-9-_]+\.)*purposity\.app$/,
        env.NEXT_PUBLIC_API_ORIGIN,
        env.NEXT_PUBLIC_APP_ORIGIN,
        env.NEXT_PUBLIC_CDN_ORIGIN,
      ],
    }),
  ],

  beforeSend(event, _hint) {
    if (isCheckly(event)) return null
    return event
  },
  beforeSendTransaction(event, hint) {
    if (isCheckly(event)) return null
    return event
  },
} satisfies BrowserOptions)

addGlobalEventProcessor(ZodProcessor)
